#productions
{
    clear: both;
    margin-top: 80px;
    padding-top: 50px;
}

#prod-swiper-screen
{
    width: 100vw;
    height: 693px;
    overflow: hidden;
}

#prod-swiper-container
{
    width: 2014px;
    margin-left: calc((100vw - 2014px) / 2);
}

.prod-production
{
    width: 386px;
    height: 217px;
    margin-bottom: 21px;
    background-size: cover;
    background-position: center;
    position: relative;
}

#prod-fade
{
    height: 693px;
    margin-top: -714px;
    position: relative;
    z-index: 200;
    pointer-events: none;
}

#prod-fade-left
{
    width: 386px;
    height: 100%;
    position: absolute;
    left: calc((100vw - 1200px) / 2); /* position at edge of slider */
    margin-left: -407px; /* margin negative of width + gutter to set anchor point to right */
    background: url('./../img/fade-left.png') top left no-repeat;
    background-size: 100% 100%;
}

#prod-fade-right
{
    width: 386px;
    height: 100%;
    position: absolute;
    right: calc((100vw - 1200px) / 2); /* position at edge of slider */
    margin-right: -407px; /* margin negative of width + gutter to set anchor point to left */
    background: url('./../img/fade-right.png') top right no-repeat;
    background-size: 100% 100%;
}


.prod-production-overlay
{
    background-color: #00000080;
    height: 94px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-transform: uppercase;
    font-family: proxima-nova-condensed, sans-serif;
    font-weight: 300;
    padding: 24px 30px 0 30px;
}

.prod-production-title
{
    font-size: 26rem;
    line-height: 1em;
    color: #ffffff;
}

.prod-production-subTitle
{
    font-size: 16rem;
    line-height: 1em;
    padding-left: 1px;
}

#prod-arrows, #prod-single-arrows
{
    margin-bottom: -48px;
    height: 36px;
}

#production
{
    clear: both;
}

#prod-single-gallery
{
    margin-right: -20px;
}

.prod-single-image
{
    float: left;
    width: 285px;
    height: 160px;
    background-size: cover;
    background-position: center;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.prod-single-trailer
{
    cursor: pointer;
    position: relative;
}

.prod-single-trailer-background
{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 0.4;
    background-size: cover;
    background-position: center;
}

.prod-single-trailer-play
{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: url('./../img/arrow-right.svg') no-repeat;
    background-size: 36px 36px;
    background-position: center;
}

#prod-single-trailer-content
{
    display: none;
}

#prod-single-trailer-iframe
{
    padding: 0;
    margin: 0;
    background: #000000;
    width: 70vw;
    height: calc(70vw / 16 * 9);
}

.fancybox__content
{
    padding: 0 !important;
}

#prod-single-content
{
    clear: both;
    padding-top: 50px;
    overflow: auto;
}

#prod-single-synopsis
{
    float: left;
    width: calc(50% - 10px);
    margin-right: 20px;
    padding-right: 40px;
}

#prod-single-synopsis p
{
    margin: 0 0 1em 0;
}

#prod-single-synopsis h3, #prod-single-credits h3
{
    margin-bottom: 1em;
}

#prod-single-credits
{
    float: left;
    width: calc(50% - 10px);
}


#prod-single-credits table tbody tr td
{
    vertical-align: top;
}

#prod-single-credits table tr td:nth-child(1)
{
    color: #ffffff;
    padding-right: 30px;
}

.prod-single-website
{
    margin-top: 1em;
}


@media screen and (max-width: 1333px) 
{
    #prod-swiper-container
    {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }

    .prod-production
    {
        width: calc((90vw - 42px) / 3);
        height: calc(((90vw - 42px) / 3) / 16 * 9);
    }

    #prod-swiper-screen
    {
        height: calc(((((90vw - 42px) / 3) / 16 * 9) * 3) + 42px)
    }

    #prod-fade
    {
        display: none;
    }

    .prod-single-image
    {
        width: calc(((90vw + 20px) / 4) - 20px);
        height: calc((((90vw + 20px) / 4) - 20px) / 16 * 9);
    }
}

@media screen and (max-width: 1100px) 
{
    .prod-production
    {
        width: calc((90vw - 21px) / 2);
        height: calc(((90vw - 21px) / 2) / 16 * 9);
    }

    #prod-swiper-screen
    {
        height: calc(((((90vw - 21px) / 2) / 16 * 9) * 3) + 42px)
    }

    .prod-single-image
    {
        width: calc(((90vw + 20px) / 2) - 20px);
        height: calc((((90vw + 20px) / 2) - 20px) / 16 * 9);
    }

    #prod-single-synopsis
    {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
    }

    #prod-single-credits
    {
        width: 100%;
        margin-top: 40px;
    }
}

@media screen and (max-width: 960px) 
{
    #prod-arrows, #prod-single-arrows
    {
        margin-bottom: -32px;
    }
}


@media screen and (max-width: 750px) 
{
    .prod-production
    {
        width: calc(90vw);
        height: calc(90vw / 16 * 9);
    }

    #prod-swiper-screen
    {
        height: calc(((90vw / 16 * 9) * 3) + 42px)
    }

    .prod-single-image
    {
        width: 90vw;
        height: calc(90vw / 16 * 9);
    }
}