#home
{
    
}

#home-arrows
{
    margin-top: -120px;
    height: 120px;
}

.home-production
{
    height: calc(100vh);
    background-position: center;
    background-size: cover;
}

.home-production-overlay
{
    background-color: #00000080;
    height: 210px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-transform: uppercase;
    font-family: proxima-nova-condensed, sans-serif;
    font-weight: 300;
    padding-top: 60px;
}

.home-production-title
{
    font-size: 60rem;
    line-height: 1em;
    color: #ffffff;
}

.home-production-title .button
{
    margin-left: 30px;
}

.home-production-subTitle
{
    font-size: 24rem;
    line-height: 1em;
    padding-left: 2px;
}

@media screen and (max-width: 960px) 
{
    .home-production-title {
        font-size: 30rem;
    }

    .home-production-subTitle {
        font-size: 16rem;
    }

    .home-production-title .button {
        display: block;
        width: fit-content;
        clear: both;
        margin: 0.5em 0;
    }

    .home-production-overlay
    {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
    }
}