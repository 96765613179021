@import url("https://use.typekit.net/for0hpt.css");

/* Set padding to be subtracted from height/width instead of added to it */
* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box; 
}

table
{
    border-collapse: collapse;
}

:root {
    --textYellow: #e4ac23;
    --textNormal: #999999;
    --textBright: #ffffff;
}

html
{
    font-size: 1px;
}

body
{
    background: #000000;
}

.app
{
    width: auto;
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16.5rem;
    line-height: 1.6em;
    color: var(--textNormal);
}

strong {
	font-weight: 800;
}

a {
    text-decoration: none;
    color: inherit;
}

h1, h2, h3 {
    text-transform: uppercase;
    font-family: proxima-nova-condensed, sans-serif;
    font-weight: 300;
    font-size: 44rem;
    line-height: 1em;
    margin: 0;
    color: #ffffff;
}

h2 {
    font-size: 60rem;
}

h3 {
    font-size: 24rem;
    font-family: proxima-nova, sans-serif;
}


.title
{
    margin-bottom: 50px;
}

.content
{
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.arrowContent
{
    width: 1060px;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
}

.arrows
{
    position: relative;
    z-index: 500;
    pointer-events: none;
}

.arrows div img
{
    width: 36px;
    height: 36px;
}

.arrows div
{
    opacity: 0.2;
    width: 36px;
    height: 36px;
    cursor: pointer;
    pointer-events: all;
}

.arrowLeft
{
    float: left;   
    background: url('./../img/arrow-left.svg');
}

.arrowRight
{
    float: right;   
    background: url('./../img/arrow-right.svg');
}

.line
{
    margin-top: 110px;
    margin-bottom: 100px;
    height: 2px;
    background: #ffffff;
    opacity: 0.2;
}

.button
{
    font-family: proxima-nova-condensed, sans-serif;
    color: #ffffff;
    font-size: 20rem;
    text-transform: uppercase;
    line-height: 1em;
    display: inline-block;
    padding: 0.5em 1em;
    vertical-align: calc(0.5em + 2px);
    border: 2px solid #ffffff;
    opacity: 0.5;
}

.button:hover
{
    opacity: 1;
}

@media screen and (max-width: 1333px) 
{
    .content {
        width: 90vw;
    }

    .arrowContent {
        width: calc(90vw - 140px);
    }
}

@media screen and (max-width: 960px) 
{
    .button
    {
        font-size: 16rem;
    }

    h2 {
        font-size: 30rem;
    }

    h3 {
        font-size: 16rem;
    }

    .line
    {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .arrowContent {
        width: calc(90vw - 100px);
    }
}