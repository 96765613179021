#menu-main
{
    height: 70px;
    padding-top: 12px;
    background: #090909;
    position: absolute;
    top:0;
    width: 100%;
    z-index: 1000;
}

#menu-main-hamburger
{
    display: none;
}

#menu-main a
{
    font-size: 24rem;
    text-transform: uppercase;
    font-family: proxima-nova-condensed, sans-serif;
    font-weight: 300;
    margin-right: 16px;
}

#menu-main a.active
{
    color: #ffffff;
}

#menu-main h1
{
    color: #ffffff;
    display: inline;
    margin-right: 16px;
    cursor: pointer;
}

@media screen and (max-width: 960px) 
{
    #menu-main
    {
        height: 100px;
    }

    #menu-main h1
    {
        display: block;
        margin-bottom: 4px;
    }
}