#contact
{
    clear: both;
    min-height: 100vh;
}

#contact a
{
    color: #ffffff;
}

.contact-quarter
{
    margin-top: 60px;
    float: left;
    width: 20%;
    margin-right: 5%;
    white-space: pre-wrap;
}

.contact-half
{
    margin-top: 60px;
    float: left;
    width: 50%;
    white-space: pre-wrap;
}

@media screen and (max-width: 1100px) 
{
    /* double */
    .contact-quarter
    {
        width: 45%;
        margin-right: 5%;
    }

    .contact-quarter:nth-child(3)
    {
        width: 50%;
        margin-right: 0;
    }

    .contact-half
    {
        width: 100%;
    }
}

@media screen and (max-width: 750px) 
{
    /* single */
    .contact-quarter, .contact-quarter:nth-child(3), .contact-half
    {
        width: 100%;
        margin-right: 0;
    }
}