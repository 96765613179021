body
{
	font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

#bs
{
	background: #ffffff;
}

.bs-container {
    max-width: 1000px;
    padding: 20px;
    margin: 0 auto;
}

.bs-separator-50 {
    clear: both;
    height: 50px;
}

.bs-separator-40 {
    clear: both;
    height: 40px;
}

#bs p
{
    margin: 0 0 16px 0;
}

#bs-language {
    background: #000000;
    color: #ffffff;
    position: fixed;
    top: 0;
    right: 5%;
    padding: 4px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
}

#bs-language button
{
    text-transform: uppercase;
    margin: 0 4px;
}

#bs-title {
	text-transform: uppercase;
    font-family: "proxima-nova-condensed", sans-serif;
    font-weight: 200;
    font-size: 60px;
	line-height: 60px;
	text-align: center;
    color: #000000;
}

#bs-subtitle {
	font-size: 18px;
	font-weight: 200;
	line-height: 20px;
	text-align: center;
	color: #999999;
}

#bs-trailer {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	width: 650px;
    height: calc(650px / 16 * 9);
}

#bs-trailer iframe {
	width: 100%;
	height: 100%;
}

#bs-description {
	margin-top: 30px;
	text-align: center;
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-wrap;
}

#bs-awards {
	margin-top: 30px;
	text-align: center;
}

.bs-clear {
	clear: both;
}

.bs-black
{
    background: #000000;
    color: #ffffff;
    width: 100%;
}

.bs-episode {
	clear: both;
	padding-top: 80px;
}

.bs-episode-title {
	font-family: "proxima-nova-condensed", sans-serif;
	font-size: 28px;
	line-height: 28px;
	font-weight: 200;
	margin-bottom: 40px;
	text-transform: uppercase;
}

.bs-episode-flex {
	display: inline-flex;
}

.bs-episode-image {
	margin-right: 40px;
}

.bs-episode-image img {
	width: 400px;
	height: auto;
}

.bs-episode-description {
	font-size: 14px;
	line-height: 16px;
	padding-top:0px;
}

.bs-episode-description p {
	margin-bottom: 16px;
}

.bs-episode-button {
	font-size: 18px;
    background: #00adef;
    display: block;
    float: right;
    padding: 8px 10px 6px 10px;
    border-radius: 4px;
    line-height: 18px;
}

.bs-footer
{
    text-align: center;
    padding: 0px 20px;
    font-size: 16px;
    color: #000000;
    line-height: 20px;
}

/* Mobile Version */
@media (max-width: 500px) {
	#bs-title {
		font-size: 36px;
		line-height: 36px;
	}
}

@media (max-width: 700px) {
	.bs-episode-flex {
		display: block;
	}

	.bs-episode-image {
		margin-right: 0px;
	}

	.bs-episode-image img {
		width: 100%;
	}

	.bs-episode-description {
		padding-top: 30px;
	}
}

@media (max-width: 690px)
{
    #bs-trailer
    {
        width: calc(100vw - 40px);
        height: calc((100vw - 40px) / 16 * 9);
    }
}